.mat-drawer-container {}

.mat-drawer-container-end {}

.sidebar .menu .list a {
    padding: 4px 9px 4px 9px;
    margin: 4px 11px 0px 11px;
}

.side-closed .sidebar .menu .list li a {
    padding: 6px 9px 6px 9px;
    margin: 4px 11px 0px 11px;
}

.side-closed.side-closed-hover .sidebar .menu .list li a {
    padding: 3px 9px 3px 9px;
    margin: 4px 11px 0px 11px;
}

.theme-white .navbar.active {
    box-shadow: 0px 0px 0px 0px #cdd4e7;
    /* box-shadow: 5px 7px 26px -5px #cdd4e7; */
}

.breadcrumb {
    background-color: transparent;
    font-size: 15px;
    align-items: center;
    margin: 3rem 0 1rem;
    padding: 0px;
    color: #555;
}

nav.navbar {
    min-height: 50px;
    height: 50px;
}

.navbar-nav .dropdown-menu {
    margin-top: 50px !important;
}

section.content .content-block {
    padding: 20px 0px 0px 15px;
}

.navbar-header {
    padding: 0px;
}

.sidebar {
    top: 50px;
    height: calc(100vh - 0px);
}

.navbar-nav.navbar-right .user_profile .user_img {
    margin: 5px 0px 0px 10px;
}

.lang-dropdown {
    margin: 5px 20px 0px 10px;
}

section.content {
    margin: 0px 27px 0 260px;
}

.navbar .collapse-menu-icon {
    line-height: 0px;
}

.navbar-nav.navbar-right {
    line-height: 40px;
}

.navbar-brand {
    padding: 10px 25px;
}

.rightalg {
    float: right;
}

.leftalg {
    float: left;
}

.p-l-10 {
    padding-left: 10px;
}

.p-t-10 {
    padding-top: 10px;
}

.p-b-10 {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.s-m-t {
    margin: 0px;
    padding: 0px;
    border: none;
    padding-top: 10px;
}

.g-h-a {
    text-align: right;
    float: right;
    display: block;
}

.g-c-av {
    text-align: right;
    float: right;
    display: block;
}

//////Text Box
.mb-5 {
    margin-bottom: 0.5rem !important;
}

.mb-2 {
    margin-bottom: 0.1rem !important;
}

.mb-3 {
    margin-bottom: 0rem !important;
}

.m-4 {
    margin: 0rem 1rem 1rem 1rem !important;
}

.card .body {
    padding: 15px 15px 15px 15px;
}

.card .body0 {
    padding: 0px 0px 0px 0px;
}

.breadcrumb .page-title:after {
    content: ":";
}

.card {
    border-radius: .2em;
    box-shadow: none;
}

.cardtopbc {
    border-style: solid;
    border-width: thin 0px 0px 0px;
    border-color: green;
}

/*
.mat-form-field-appearance-outline .mat-form-field-infix .mat-input-element  { 
    position: relative; 
    bottom: .45em;     
  }
  
.mat-form-field-infix {
    border-top: 1.2em solid rgba(0, 0, 0, 0);    
}

.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.0em 0 0.1em 0;
}

.mat-form-field-label-wrapper {
    top: -1.6em;
    padding-top: 1.8375em;    
}

.mat-form-field-wrapper {
    padding-bottom: 0em;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0em 0;
}
 
.mat-header-row {
    min-height: 48px;
}

.mat-row,
mat-footer-row {
    min-height: 35px;
} */

.table-img img {
    border-radius: 2px;
    height: 28px;
    width: 28px;
}

.task-container .header .header-close {
    top: 3px;
    right: 5px;
    list-style: none;
}

.materialTableHeader {
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 5px;
    padding-bottom: 0px;
    margin-bottom: -12px;
    min-width: auto;
}

.mthp20 {
    padding-left: 10px;
    padding-right: 10px;
}

button.mat-menu-item {
    height: 35px;
    line-height: 35px;
}

.modalCDFooter {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fafafa;
}

.modalCDHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 5px;
    background-color: hwb(186 71% 22% / 0.212);
}

.modalPCDHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: hwb(186 71% 22% / 0.212);
}

/*Header */
.card1 {
    border: 0;
    box-shadow: 0px 2px 4px rgb(7 4 146 / 10%), 0px 24px 60px rgb(6 47 125 / 5%);
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0.475rem;
}

.card1 .card-body1 {
    padding: 2rem 1.25rem;
}

.h-100 {
    height: 100% !important;
}

.card-body1.p-4 {
    padding: 0.5rem 1rem !important;
}

.symbol-light-success {
    background: rgb(61, 109, 169, 0.3);
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 1px;
    padding-top: 5px;
    border-radius: 0.25rem;
}

.symbol i {
    font-size: 0.8rem;
    color: #3d6d9c;
}

.font-size-lg {
    font-size: 0.8rem;
    padding-top: 5px;
    font-weight: 500;
    text-align: left;
    padding-left: 7px;
}

.font-size-sm {
    font-size: 0.7rem;
    padding-left: 14px;
}

.text-dark {
    color: #0b0a0b !important;
}

.text-muted {
    color: #0b0a0b !important;
    opacity: 0.8;
}

.bd-content-title {
    padding-bottom: 0.5rem;
    display: block;
    position: relative;
    font-style: inherit;
    font-size: 12px;
    font-weight: 500;
}

.bd-content-title span {
    position: relative;
}

.bd-content-title span:after {
    content: '';
    background-color: rgb(11, 10, 11, 0.2);
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -2px;
    left: 0px;
}

.bd-content-title {
    font-size: 0.9rem;
}

.px-2 {
    padding-left: 0.3rem !important;
    padding-right: 0.3rem !important;
}

.pb-2 {
    padding-bottom: 0.6rem !important;
}

.mrb-10 {
    margin-bottom: 10px;
}

.mrt-10 {
    margin-top: 10px;
}

.custom-dialog-container .mat-dialog-container {
    padding: 0px 0px 10px 0px;
    overflow-block: true;
    overflow-y: hidden;
    overflow-x: hidden;

}

.mat-tab-header {
    height: 35px;
    background: -webkit-linear-gradient(top, #f6eded, #f9f9f9);
}

.mat-tab-label {
    min-width: 100px !important;
    padding: 10px;
    height: 35px;
    color: #392c7d;
    opacity: unset;
}

/* Styles for the active tab label */
.mat-tab-label.mat-tab-label-active {
    min-width: 100px !important;
    padding: 10px;
    background-color: hwb(287 10% 48% / 0.212);
    height: 35px;
    color: #ff0000;
}

/* Styles for the ink bar */
.color_green {
    color: #37ae37;
}

.color_redgray {
    color: #b4adad;
}

.color_red {
    color: #f30505;
}

.no-results {
    font-size: 13px;
    text-align: center;
    color: red;
    padding: 10px;

}

.sidenav__content--height {
    min-height: calc(100vh - 26.5vh);
}

.formlablel {
    font-style: inherit;
    font-size: 12px;
    text-align: left;
    padding-left: 10px;

    height: 25px;
}

.formfield {
    font-style: inherit;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
}

.mat-paginator {
    background-color: transparent;
}


.mat-drawer {
    width: 100%;
}

@media only screen and (min-width :320px) and (max-width :480px) {
    .size40 {
        width: 100%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .mat-drawer.mat-drawer-side {
        width: 500p;
    }

    .size40 {
        width: 40%;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .mat-drawer.mat-drawer-side {
        width: 500p;
    }

    .size40 {
        width: 40%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .mat-drawer.mat-drawer-side {
        width: 500px;
    }

    .size40 {
        width: 40%;
    }

}

.col-form-label {
    font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto, Helvetica", "Arial, sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 11px;
    font-weight: 400;
}


.col-form-value {
    font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto, Helvetica", "Arial, sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 13px;
    font-weight: 500;
    padding-top: 5px;
}

.form-control.is-invalid:focus {
    border-color: none;
    box-shadow: none;
}

.form-control:focus {
    border-color: none;
    box-shadow: none;
}

.form-control {
    font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto, Helvetica", "Arial, sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 12px;
    padding: 0.215rem 0.75rem;
}

.form-control:invalid,
.form-control.is-invalid {
    background-image: none;
}

.invalid-feedback {
    font-size: 0.755em;
}

.lableR {
    padding-right: 5px;
    display: inline;
}

.radioR {
    padding-top: 5px;
}

.SpanR {
    vertical-align: top;
}

.accountBorder {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/*    .radio-button {
    margin: 15px;
  }
  .radio-button input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  .radio-button label {
    display: inline-block;
    background-color: #d1d1d1;
    padding: 10px 20px;
    font-family: sans-serif, Arial;
    font-size: 16px;
    border: 1px solid #666;
    border-radius: 4px;
  }
  .radio-button label:hover {
    background-color: #b1e3c1;
  }
  .radio-button input[type="radio"]:focus + label {
    border: 2px dashed #666;
  }
  .radio-button input[type="radio"]:checked + label {
    background-color: #76cf9f;
    border-color: #666;
  }  */


.mat-date-range-input-inner {
    position: relative;
    bottom: .00em;
}

.custom-img-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-img-input::before {
    content: 'Select Profile Pic';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 2px 5px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 600;
    font-size: 8pt;
}

.custom-img-input:hover::before {
    border-color: black;
}

.custom-img-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.custom-img2-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-img2-input::before {
    content: 'Select Cover Video';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 2px 5px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 600;
    font-size: 8pt;
}

.custom-img2-input:hover::before {
    border-color: black;
}

.custom-img2-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}


.custom-img1-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-img1-input::before {
    content: 'Select Cover Pic';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 2px 5px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 600;
    font-size: 8pt;
}

.custom-img1-input:hover::before {
    border-color: black;
}

.custom-img1-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}


.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
    text-align: center;
}

.custom-file-input::before {
    content: 'Select files';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 2px 5px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 600;
    font-size: 8pt;
}

.custom-file-input:hover::before {
    border-color: black;
    text-align: center;
}

.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    text-align: center;
}

.preview2 {
    margin: 5px;
    padding: 2px;
    width: 300px;
    height: 140px;
    border: 1px solid black;
    text-align: center;
}

.preview3 {
    margin: 1px;
    padding: 1px;
    width: 50px;
    height: 50px;
    border: 1px solid black;
    text-align: center;
}

.preview1 {
    margin: 1px;
    padding: 1px;
    width: 300px;
    height: 140px;
    border: 1px solid black;
    text-align: center;
}

.preview {
    margin: 5px;
    padding: 2px;
    width: 300px;
    height: 140px;
    border: 1px solid black;
    text-align: center;
}

.ck-editor__editable_inline {
    min-height: 170px !important;
}

.mat-paginator-container {
    min-height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: hwb(186 71% 22% / 0.212);

    .mat-paginator-page-size {
        height: 100%;
        margin-top: -21px; // difference between original height (56px) and reduced height (35px)
        overflow: hidden;
    }

    .mat-paginator-range-actions {
        height: 100%;
    }
}

.small-height-input {
    font-size: 13px !important; // decrease the font size  to set height

    .mat-input-element {
        font-size: 13px !important; //increase the size of input font
    }
}

.mat-row {
    min-height: 35px;
    font-size: 12px;
}

.mat-header-row {
    min-height: 40px;
}

.mat-table .mat-header-cell {
    font-size: 11px;
    font-weight: 600;
}

.mat-header-cell {
    color: rgb(33, 32, 32);
    background-color: hwb(186 71% 22% / 0.212);
    min-height: 40px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

mat-cell {
    font-size: 12px;
    max-height: 35px;
}

mat-row:nth-child(even) {
    background-color: rgb(255, 255, 255);
}

.mat-row:nth-child(odd) {
    background-color: rgb(243, 243, 243);
}

.mat-row:hover,
.mat-row:hover {
    background-color: rgb(222, 223, 223);
}

.required {
    color: red;
    font-weight: bold;
}

.card .header h2,
.card .header .h2 {
    margin: 0;
    color: #5b626b;
    font-size: 14px;
    line-height: 28px;
    padding-right: 10px;
    font-weight: 500;
}

.mat-icon-button {
    padding: 0;
    min-width: 0;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    line-height: 30px;
    border-radius: 50%;
}

.logo-name {
    color: red;
    font-weight: bold;
}

.logo-white .navbar-header .logo-name {
    color: red;
}


.boderright {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.subs-titlerow {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 2px;
    margin-top: 0px;
}

.subs-htitlerow {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 2px;
    margin-top: 0px;
    padding-top: 5px;
    height: 35px;
    background: -webkit-linear-gradient(bottom, #f6eded, #f9f9f9);
}

.subs-title {
    font-size: 12px;
    font-weight: 600;
    color: #392c7d;
}

.mStatus {
    font-size: 10px;
    font-weight: 400;
    color: white;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    background-color: rgb(74, 14, 113);
}

.fStatus {
    font-size: 10px;
    font-weight: 400;
    color: black;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    background-color: rgb(230, 130, 200);
}


.yStatus {
    font-size: 10px;
    font-weight: 400;
    color: white;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    background-color: green;
}

.nStatus {
    font-size: 10px;
    font-weight: 400;
    color: white;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    background-color: red;
}


.acceptStatus {
    font-size: 10px;
    font-weight: 400;
    color: white;
    padding: 5px;
    border-radius: 5px;
    background-color: green;
}

.rejectStatus {
    font-size: 10px;
    font-weight: 400;
    color: white;
    padding: 5px;
    border-radius: 5px;
    background-color: red;
}

.pendingStatus {
    font-size: 10px;
    font-weight: 400;
    color: black;
    padding: 5px;
    border-radius: 5px;
    background-color: yellow;
}

.AllStatus {
    font-size: 10px;
    font-weight: 400;
    color: white;
    padding: 5px;
    border-radius: 5px;
    background-color: #3d6d9c;
}

.pinvalid-feedback {
    font-size: 0.755em;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
}

.avatar-upload {
    position: relative;
    max-width: 80px;
    margin: 10px auto;

    .avatar-edit {
        position: absolute;
        right: 12px;
        z-index: 1;
        top: 10px;

        input {
            display: none;

            +label {
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-bottom: 0;
                border-radius: 100%;
                background: #FFFFFF;
                border: 1px solid transparent;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                cursor: pointer;
                font-weight: normal;
                transition: all .2s ease-in-out;

                &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                }

                &:after {
                    content: "U";
                    font-family: 'FontAwesome';
                    color: #757575;
                    position: absolute;
                    top: 0px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                }
            }
        }
    }

    .avatar-preview {
        width: 70px;
        height: 70px;
        position: relative;
        border-radius: 100%;
        border: 6px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

        >div {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}